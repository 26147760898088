<template>
  <div class="mainPage">
    <p class="pageTitle">Shipping Information</p>
    <div class="pageWrap">
      <a-form :label-col="{ span: 8 }"
        :wrapper-col="{ span: 12 }"
        style="width: 1000px;">
        <a-divider orientation="left">export order</a-divider>
        <a-form-item label="Submission time(utc-8）">
          <a-range-picker
            valueFormat="YYYY-MM-DD"
            style="width: 250px;"
            v-model="dateRange"
          />
          <a-button type="primary" class="ml16" :disabled="!!!dateRange.length" @click="handleExport">export</a-button>
        </a-form-item>
        <a-divider orientation="left">Update the imported order information to "Shipment Processed"</a-divider>
        <a-form-item label="Please select a file">
          <a-upload
            name="file"
            accept=".xlsx"
            :action="shipmentUrl"
            :showUploadList="false"
            :headers="headers"
            @change="handleChange"
          >
            <a-button> <a-icon type="upload" /> Click to Upload </a-button>
          </a-upload>
        </a-form-item>
        <a-divider orientation="left">Update the imported waybill number to renew</a-divider>
        <a-form-item label="Please select file(s)">
          <a-upload
            name="file"
            accept=".xlsx"
            :showUploadList="false"
            :multiple="true"
            :action="pickingUrl"
            :headers="headers"
            @change="handleChange"
          >
            <a-button> <a-icon type="upload" /> Click to Upload </a-button>
          </a-upload>
        </a-form-item>
        <a-divider orientation="left">Export audit data</a-divider>
        <a-form-item label="Submission time(utc-8）">
          <a-range-picker
            valueFormat="YYYY-MM-DD"
            style="width: 250px;"
            v-model="auditDateRange"
          />
          <a-button type="primary" class="ml16" :disabled="!!!auditDateRange.length" @click="handleExportAudit">export</a-button>
        </a-form-item>
        <!-- <a-divider orientation="left">The imported order information is updated to "Finish"</a-divider>
        <a-form-item label="Please select a file">
          <a-upload
            name="file"
            accept=".csv"
            :action="logisticUrl"
            :showUploadList="false"
            :headers="headers"
            @change="handleChange"
          >
            <a-button> <a-icon type="upload" /> Click to Upload </a-button>
          </a-upload>
        </a-form-item> -->
        <a-form-item label="Suspected duplicate work order export">
          <a-button @click="exportDuplicateTicketInfo">Export</a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import { getUSAtime } from '@/utils/utils.js'

export default {
  name: 'ShipInfo',
  data () {
    return {
      auditDateRange: [],
      dateRange: [],
      shipmentUrl: PAGECONF.hostApi + '/platform/admin/ticketAdminManage/v1/importPickingInfo',
      pickingUrl: PAGECONF.hostApi + '/platform/admin/ticketAdminManage/v1/logisticsNoUpdate',
      logisticUrl: PAGECONF.hostApi + '/platform/admin/ticketAdminManage/v1/importErpLogisticsInfo',
      uploadHeader: { "Content-Type": "multipart/form-data" },
      headers: { "token": localStorage.getItem('token') }
    }
  },
  methods: {
    exportDuplicateTicketInfo () { 
      this.$http.post('/platform/admin/ticketAdminManage/v1/exportDuplicateTicketInfo').then(res => {
        location.href = res.data
      })
    },
    handleChange(info) {
      if (info.file.status === 'done') {
        if (info.file.response.code === 0) {
          this.$message.success(`${info.file.name} file import successfully`)
        } else {
          this.$error({
            title: 'Error',
            content: info.file.response.errorMsg
          })
        }
      } else if (info.file.status === 'error') {
        this.$message.error('network error')
      }
    },
    handleExport () {
      this.$http.post('/platform/admin/ticketAdminManage/v1/exportPickingInfo', {
        startTime: getUSAtime(this.dateRange[0] + ' 00:00:00', 'America/New_York'),
        endTime: getUSAtime(this.dateRange[1] + ' 23:59:59', 'America/New_York')
      }).then(res => {
        location.href = res.data
      })
    },
    handleExportAudit () {
      this.$http.post('/platform/admin/ticketAdminManage/v1/exportReviewInfo', {
        startTime: getUSAtime(this.auditDateRange[0] + ' 00:00:00', 'America/New_York'),
        endTime: getUSAtime(this.auditDateRange[1] + ' 23:59:59', 'America/New_York')
      }).then(res => {
        location.href = res.data
      })
    }
  },
}
</script>

<style scoped lang="less">
.ant-divider-horizontal.ant-divider-with-text-left{
  margin: 56px 0 16px 0;
}
</style>